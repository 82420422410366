.footer {
    background-color: rgb(30, 30, 30, 0.8);
    color: white;
    bottom: 0%;
    position: fixed;
    right: 0%;
    padding: 4px;
    font-size: 75%;
}

.footer > span > a {
    font-weight: bold;
    color: rgb(200, 200, 200);
}
.header {
    background-color: rgb(30, 30, 30, 0.8);
    color: white;
    margin-bottom: 24px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 24px;
    padding: 4px;
}

.search-box {
    margin: auto;
    width: 90%;
    max-width: 480px;
}

body {
  background: url('https://images.unsplash.com/photo-1517344978461-f9e40931fc00?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=3900&q=80') no-repeat center center fixed;
  background-size: cover; -ms-background-size: cover; -o-background-size: cover; -moz-background-size: cover; -webkit-background-size: cover;
  text-align: center;
}

