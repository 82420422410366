.footer {
    background-color: rgb(30, 30, 30, 0.8);
    color: white;
    bottom: 0%;
    position: fixed;
    right: 0%;
    padding: 4px;
    font-size: 75%;
}

.footer > span > a {
    font-weight: bold;
    color: rgb(200, 200, 200);
}